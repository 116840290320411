import Helmet from 'react-helmet';
// @flow strict
import React from 'react';
import type {Node as ReactNode} from 'react';
import styles from './Layout.module.scss';
import {useSiteMetadata} from '../../hooks';
import {withPrefix} from 'gatsby';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  socialImage?: string,
  slug?: string,
};

const Layout = ({children, title, description, socialImage, slug}: Props) => {
  const {author, url} = useSiteMetadata();
  const metaImage = socialImage != null ? socialImage.slice(1) : author.photo;
  const metaImageUrl = `${url.slice(0, -1)}${withPrefix(metaImage)}`;

  const currentUrl = slug === undefined ? url : `${url}${slug.slice(1)}`;

  return (
    <div className="bg-gray-100 dark:bg-black text-gray-900 dark:text-gray-100">
      <div className={`${styles.layout}`}>
        <Helmet>
          <html lang="ja" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:site_name" content={title} />
          <meta property="og:image" content={metaImageUrl} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={metaImageUrl} />
          <link rel="canonical" href={currentUrl} />
        </Helmet>
        {children}
      </div>
    </div>
  );
};

export default Layout;
